@import "basics/index";
@import "components/index";

.turn {
    position: absolute;
    top: 20px;
    left: 20px;
    color: white;
    font-size: 24px;
}

.tracker {
    position: absolute;
    top: 60px;
    left: 20px;
    color: white;
    font-size: 24px;
}

.timer {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 30px;
}
