.end-screen {
  position: absolute;
  top: 20vh;
  left: 0;
  width: 100%;
  height: 80vh;
  background-color: #262626;
  z-index: 500;
  display: none;
  justify-content: center;
  padding-top: 50px;
  box-sizing: border-box;
  &--active {
    display: flex;
    @extend .end-screen;
  }
  &__container {
    width: 400px;
    height: 100%;
  }
  &__time {
    display: flex;
    flex-direction: column;
    align-items: center;
    &:before {
      content: "";
      display: block;
      background-image: url("./../../img/winner.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 112px;
      height: 120px;
      margin-bottom: 20px;
    }
  }
  &__heading-2 {
    font-size: 60px;
    font-weight: 500;
    margin: 0 0 20px 0;
  }
  &__counter {
    @extend .footer__counter;
    &-title {
      @extend .footer__counter-title;
    }
    &-num {
      @extend .footer__counter-num;
    }
  }
  &__retry {
    position: absolute;
    bottom: 40px;
    &-button {
      @extend .footer__start-button;
    }
  }
}
