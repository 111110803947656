body {
  margin: 0;
  font-family: $font, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

.App {
  height: 100%;
  width: 100%;
  position: relative;
  color: white;
  background-color: #262626;
}

button {
  font-family: $font;
}
